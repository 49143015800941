.nav-signin-btn {
  border: 2px solid #e06a56;
  color: #e06a56 !important;
  border-radius: 5px;
  padding: 8px 35px;
  font-weight: 600;
}

.nested.dropdown:hover > .dropdown-menu {
  display: block;
}
.nested.dropdown .dropdown-menu {
  top: -12px;
  margin-left: 100%;
}
.nested.dropdown .dropdown-trigger button::after {
}
.nested.dropdown .dropdown-trigger button {
  padding: 0px 0px;
  border: 0px;
  font-size: 14px;
  font-weight: 400;
}

@import "../../vars.scss";

.footer-container {
  background-color: $brand-eggplant;
  color: $brand-white;
}
.footer-header {
  font-size: 35px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  @media (min-width: $tablet) {
    margin-left: 250px;
    margin-right: 250px;
  }
}

.FooterComponent__container {
  font-size: 0.7rem;
  margin-top: 50px;

  text-align: center;
  @media (min-width: $tablet) {
    text-align: left;
  }

  .logo-img {
    max-width: 15px;
    max-height: 15px;
    margin-right: 5px;
  }

  .menu-label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: none;
    letter-spacing: 0;
    color: inherit;
    &.menu-label:not(:last-child) {
      margin-bottom: 0.6rem;
    }
  }

  .menu-list {
    a {
      color: #ffffff;
    }
    margin-left: -0.5rem;
    font-size: 1rem;
    img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.8rem;
    }
    // Slightly transparent hover background
    // makes it look good on any section background.
    a:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

.FooterComponent__logo {
  width: 50px;
  @media (min-width: $tablet) {
    width: 150px;
  }
}

.FooterComponent__description {
  text-align: center;
  @media (min-width: $tablet) {
    text-align: left;
    width: 70%;
  }
}

.FooterComponent__copywrite {
  margin-top: 1rem;
}

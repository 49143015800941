// COLORS

$brand-white: #ffffff;
$brand-elephant: #cdc7cf;
$brand-dusk: #4f566b;
$brand-sienna: #e06a56;
$brand-eggplant: #392330;
$brand-black: #000000;
$brand-blue: #52689e;
$brand-green: #429d84;
$brand-sky-blue: #e4ebfc;
$homepage-light: #f9f9f9;
$brand-light-green: #e9f1e8;

// BREAKPOINTS
$gap: 32px;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
$widescreen-enabled: true;
$fullhd-enabled: false;

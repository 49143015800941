@charset "utf-8";

// FONTS
@import url("https://fonts.googleapis.com/css?family=Poppins:200,400,700&display=swap");

body {
  font-family: "Poppins" !important;
}

$brand-white: #ffffff;
$brand-elephant: #cdc7cf;
$brand-sienna: #e06a56;
$brand-eggplant: #392330;
$brand-black: #000000;
$brand-blue: #52689e;
$brand-green: #429d84;

// TEXT
$text: #000000;
$link: #52689e;
$body-family:
  BlinkMacSystemFont,
  -apple-system,
  "Segoe UI",
  "Roboto",
  "Oxygen",
  "Ubuntu",
  "Cantarell",
  "Fira Sans",
  "Droid Sans",
  "Helvetica Neue",
  "Helvetica",
  "Arial",
  sans-serif;
$title-family: false;
$button-family: false;

// BREAKPOINTS
$gap: 32px;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
$widescreen-enabled: true;
$fullhd-enabled: false;

// LAYOUT
$section-padding: 3rem 1.5rem;
$section-padding-medium: 6rem 1.5rem;
$section-padding-large: 9rem 1.5rem;

// IMPORT BULMA
@import "~bulma/bulma.sass";
@import "~bulma-switch";

.MuiButton-label {
  font-family: "poppins";
  font-size: 16px;
  text-transform: none;
}

.blueOutlined {
  color: $brand-blue;
  background-color: $brand-white;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid $brand-blue;
  border-radius: 5px;
  padding: 10px 75px;
  cursor: pointer;
  &:hover {
    background-color: $brand-blue;
    border-color: $brand-blue;
    color: $brand-white;
  }
}

.blueFilled {
  color: $brand-white;
  background-color: $brand-blue;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid $brand-blue;
  border-radius: 5px;
  padding: 10px 75px;
  cursor: pointer;
  &:hover {
    background-color: $brand-white;
    border-color: $brand-blue;
    color: $brand-blue;
  }
  &:disabled,
  button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
}

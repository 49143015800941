@import "../../vars.scss";

.main_body {
	background-color: $brand-white;
	color: $brand-dusk;
	margin-top: 0px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 50px;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-size: 14px;
	text-align: justify;

	@media (min-width: $tablet) and (max-width: $desktop) {
		font-weight: 500;
		font-size: 18px;
	}

	@media (min-width: $desktop) {
		font-weight: 500;
		font-size: 18px;
	}

	.ctas {
		text-align: center;
		margin-left: 0px;
		margin-right: 0px;
		margin-top: 20px;

		@media (min-width: $tablet) {
			margin-left: 0px;
			margin-right: 0px;
			margin-top: 20px;
		}
	}

	.page_title {
		font-weight: 400;
		font-style: bold;
		font-size: 20px;
		margin-top: 20px;

		@media (min-width: $tablet) {
			font-weight: 700;
			font-size: 36px;
			margin-top: 50px;
		}
	}

	.page_blurb {
		font-style: bold;
		margin-top: 10px;
	}

	.title-img-left {
		float: left;
		width: 180px;

		margin-right: 50px;
		margin-top: 30px;
	}

	.title-img-right {
		float: right;
		width: 180px;
		margin-left: 50px;
		margin-top: 30px;
	}

	.title-img-container {
		text-align: center;
		margin-right: 10px;
		margin-left: 10px;
		margin-top: 10px;
		@media (min-width: $tablet) and (max-width: $desktop) {
			margin-right: 20px;
			margin-left: 20px;
			margin-top: 20px;
		}
		@media (min-width: $desktop) {
			margin-right: 50px;
			margin-left: 50px;
			margin-top: 50px;
		}
	}

	.title-img-center {
		width: 180px;
		text-align: center;
		@media (min-width: $tablet) and (max-width: $desktop) {
			width: auto;
		}
		@media (min-width: $desktop) {
			width: auto;
		}
	}

	.impact-green-left {
		background-color: $brand-green;
		color: $brand-white;
		font-weight: 900;
		width: 70%;
		margin-top: 1rem;
		margin-bottom: 1rem;
		margin-right: 2rem;
		font-size: 2.5rem;
		text-align: center;
		float: left;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.impact-green-right {
		background-color: $brand-green;
		color: $brand-white;
		font-weight: 900;
		width: 70%;
		margin-top: 1rem;
		margin-bottom: 1rem;
		font-size: 2.5rem;
		text-align: center;
		float: right;
		margin-left: 2rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.page_blurb {
		font-style: bold;
		margin-top: 10px;
	}

	.know_content_grid {
		margin-top: 2rem;
		width: 100%;
		.columns {
			@media (min-width: $tablet) and (max-width: $desktop) {
				padding: 20px;
			}
			@media (min-width: $desktop) {
				padding: 10px;
			}

			.columns:after {
				content: "";
				display: table;
				clear: both;
			}

			.column {
				margin: 5px;

				align-items: stretch;
				// Center card within column
				// Only has an effect if width of child card
				// is less than 100%.
				justify-content: center;

				@media (min-width: $tablet) and (max-width: $desktop) {
					margin: 20px;
				}
				@media (min-width: $desktop) {
					margin: 20px;
				}

				.image_container {
					position: relative;
					.image {
						opacity: 0.3;

						@media (max-width: $tablet) {
							size: 30px;
						}
					}

					.image_text {
						font-size: 15px;
						@media (min-width: $tablet) {
							font-size: 18px;
							font-weight: 600;
						}
					}
				}
			}

			.description {
				font-size: 1.3rem;
				font-weight: 700;
			}
		}
	}
}

.link {
	color: $brand-green;
	text-decoration: underline;
	&:hover {
		color: $brand-elephant;
	}
}

.know_text {
	font-size: 15px;
	font-style: bold;
	font-weight: 400;
	color: $brand-dusk;
	margin-top: 5px;
	@media (min-width: $tablet) {
		font-size: 18px;
		margin-top: 20px;
		font-weight: 400;
	}
}

.emphasis {
	font-size: 15px;
	font-style: bold;
	font-weight: 600;
	color: $brand-dusk;
	margin-top: 5px;
	@media (min-width: $tablet) {
		font-size: 18px;
		margin-top: 20px;
		font-weight: 600;
	}
}

.bulletPoints {
	margin-top: 10px;
	margin-bottom: 10px;
	@media (min-width: $tablet) {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.ul {
		list-style-type: square;
		margin-left: 20px;
	}
	.bulletHeader {
		.bulletHeaderText {
			color: $brand-eggplant;

			font-weight: 400;
			font-size: 18px;
			margin-top: 15px;
			text-align: left;
			vertical-align: middle;

			@media (min-width: $tablet) and (max-width: $desktop) {
				font-weight: 400;
				font-size: 20px;
			}
			@media (min-width: $desktop) {
				font-weight: 400;
				font-size: 25px;
			}
		}
		.bulletSubHeaderText {
			font-weight: 400;
			font-size: 14px;
			margin-top: 15px;
			text-align: left;
			vertical-align: middle;
			@media (min-width: $tablet) and (max-width: $desktop) {
				font-weight: 400;
				font-size: 16px;
			}
			@media (min-width: $desktop) {
				font-weight: 400;
				font-size: 20px;
			}
		}
		.bulletHeaderImage {
			margin-right: 5px;
			@media (min-width: $desktop) {
				margin-right: 10px;
			}
		}
	}

	.bulletBodyText {
		font-weight: 400;
		margin-top: 10px;
		margin-bottom: 10px;
		@media (min-width: desktop) {
			font-weight: 400;
			margin-top: 18px;
			margin-bottom: 18px;
		}
	}
}
.highlight {
	background-color: $brand-dusk;
	color: $brand-white;
}

.right-icon {
	font-size: 1rem;
	vertical-align: middle;
	padding: 0.5rem 0.5rem;
}

.about {
	color: $brand-green;
	text-decoration: underline;
}
/*-----*/
.align-left {
	text-align: left;
}

.background-light-green {
	background-color: $brand-light-green;
}

.background-light-blue {
	background-color: $brand-sky-blue;
}

.background-highlight {
	background-color: $homepage-light;
}

.border-left {
	border-style: solid;
	border-left-width: 0.5rem;
	border-top-width: 0rem;
	border-right-width: 0rem;
	border-bottom-width: 0rem;
}

.border-blue {
	border-color: $brand-blue;
}

.button {
	font-size: 0.93rem;
	font-style: normal;
	font-family: Poppins;
	border-radius: 5px;
	border-style: none;
	line-height: 1.4rem;
	font-weight: 700;
	padding: 0.93rem;
	padding-left: 1.625rem;
	padding-right: 1.625rem;
	height: 100%;
}

.button-green {
	background-color: $brand-green;
	color: $brand-white;
}

.container-basic {
	text-align: center;
	vertical-align: middle;
	padding: 2rem;

	@media (min-width: $tablet) {
		padding: 4rem;
	}
}

.container-slim {
	text-align: center;
	vertical-align: middle;
	padding: 1rem;
}

.container-narrow {
	text-align: center;
	width: 40%;
	@media (max-width: $tablet) {
		width: 100%;
	}
}

.container-wide {
	text-align: center;
	width: 85%;
	@media (max-width: $tablet) {
		width: 100%;
	}
}

.content-indented {
	text-align: left;
	padding-left: 5rem;
	@media (max-width: $tablet) {
		padding-left: 0rem;
	}
}

.content-centered {
	text-align: center;
	display: inline-block;
}

.content-non-scrollable {
	position: sticky;
	top: 1rem;
}

.corners-rounded {
	border-radius: 10px;
}

.color-blue {
	color: $brand-blue;
}

.color-green {
	color: $brand-green;
}

.color-eggplant {
	color: $brand-eggplant;
}

.color-dusk {
	color: $brand-dusk;
}

.mobile-invisible {
	@media (max-width: $tablet) {
		display: none;
	}
}

.spacer-thin {
	height: 1rem;
	@media (max-width: $tablet) {
		height: 0.5rem;
	}
}

.spacer-thick {
	height: 3rem;
	@media (max-width: $tablet) {
		height: 2rem;
	}
}

.text-body {
	font-size: 1.125rem;
	line-height: 1.625rem;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.2px;
	@media (max-width: $tablet) {
		font-size: 0.875rem;
		line-height: 1.375rem;
	}
}

.text-body-small {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.625rem;
	letter-spacing: 0.2px;
	@media (max-width: $tablet) {
		font-size: 1rem;
		line-height: 1.625rem;
	}
}

.text-hyperlink {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 0.93rem;
	line-height: 1.375rem;
	color: $brand-blue;
	text-decoration: underline;
}

.text-breadCrumbLink {
	text-decoration: none;
	color: $brand-dusk;
}

.text-h1 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 2.875rem;
	line-height: 3.25rem;
	letter-spacing: 0.2px;
	@media (max-width: $tablet) {
		font-size: 1.875rem;
		line-height: 2.5rem;
	}
}

.text-h2 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 1.875rem;
	line-height: 2.5rem;
	letter-spacing: 0.2px;
	@media (max-width: $tablet) {
		font-size: 1.5rem;
		line-height: 1.875rem;
	}
}

.text-h3 {
	font-size: 1.125rem;
	line-height: 1.625rem;
	color: $brand-dusk;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0.2px;
	@media (max-width: $tablet) {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}

.text-subheading {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 2.25rem;
	line-height: 2.625rem;
	letter-spacing: 0.2px;
	@media (max-width: $tablet) {
		font-size: 1.875rem;
		line-height: 2.625rem;
	}
}

.text-tocHeader {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.5rem;
	text-transform: uppercase;
}

.text-tocSection {
	font-family: Poppins;
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-align: left;
}
.text-tocItem {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.3125rem;
}

@import "../../vars.scss";

.is-green-btn {
  background-color: $brand-green;
  color: $brand-white;
  font-family: "Poppins";
  vertical-align: middle;
  font-size: 0.93rem;
  font-style: normal;
  border-radius: 5px;
  border-style: solid;
  line-height: 1.4rem;
  font-weight: 700;
  padding: 0.93rem;
  padding-left: 1.625rem;
  padding-right: 1.625rem;
  height: 3rem;
  &:hover {
    color: $brand-green;
    background-color: $brand-white;
    border-color: $brand-green;
  }
}

.is-orange-btn {
  background-color: #e06a56;
  color: #ffffff;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 600;
  height: 40px;
  border: none;
  width: 200px;

  &:hover {
    color: #ffffff;
  }
}

.is-blue-btn {
  background-color: #52689e;
  color: #ffffff;
  height: 40px;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 600;
  border: none;

  &:hover {
    color: #ffffff;
  }
}

.is-green-border-btn {
  width: 170px;
  height: 40px;
  background-color: $brand-white;
  color: #429d84;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 600;
  border: 3px solid $brand-green;
  vertical-align: middle;

  &:hover {
    color: $brand-white;
    background-color: $brand-green;
  }
}

.orange-text {
  color: #e06a56;
  border-color: #e06a56;
}
